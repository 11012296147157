import React from "react"
import Container from '@material-ui/core/Container';

class FileNotFound extends React.Component {
  render() {
    return <Container style={{maxWidth: '90vw', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <h1>File not found</h1>
    </Container>
  }
}
export default FileNotFound;
